<template>
    <div class="row">
        Waiting
    </div>
</template>

<script>
export default {
    mounted(){
       this.$router.push({name:'ViewPublicAssesment'});
    }
}
</script>